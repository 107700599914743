import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./content/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./content/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path:'privacy-policy',
    loadChildren: () => import('./content/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path:'terms',
    loadChildren: () => import('./content/pages/term-and-condition/term-and-condition.module').then(m => m.TermAndConditionModule)
  },
  {
    path:'about-us',
    loadChildren: () => import('./content/pages/about-us/about-us.module').then(m => m.AboutUsModule)
  },
  {
    path:'landing',
    loadChildren: () => import('./content/pages/landing/landing.module').then(m => m.LandingModule)
  },
  { path: '**', redirectTo: 'landing' } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' 
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
